var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{class:['rph-article', _vm.isFull && 'rph-article-full']},[_c('NuxtLink',{staticClass:"rph-article__link",attrs:{"to":{
      name: 'news-slug',
      params: {
        slug: _vm.articleData.id + '-' + _vm.articleData.slug,
      },
    },"aria-label":_vm.articleData.title}}),_vm._v(" "),_c('div',{staticClass:"rph-article__image"},[_c('span',{staticClass:"badge",style:({
        backgroundColor: _vm.activeCategory.bg_color || '',
        color: _vm.activeCategory.color || '',
      })},[_vm._v("\n      "+_vm._s(_vm.articleData.category.title)+"\n    ")]),_vm._v(" "),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.articleData.image.thumbnail),expression:"articleData.image.thumbnail"}],attrs:{"loading":"lazy","src":_vm.articleData.image.thumbnail,"alt":_vm.articleData.title}})]),_vm._v(" "),_c('div',{staticClass:"rph-article__content"},[_c('time',{staticClass:"rph-article__time"},[_vm._v(_vm._s(_vm.articleData.created_at))]),_vm._v(" "),_c('Heading',{staticClass:"rph-article__title",attrs:{"type":"h3"}},[_vm._v(_vm._s(_vm.articleData.title))]),_vm._v(" "),_c('p',{staticClass:"rph-article__description"},[_vm._v(_vm._s(_vm.articleData.short_description))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }